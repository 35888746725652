import * as serializers from '@/constants/serializer';
import * as schemaItemTypes from './schemaItemTypes';
import * as onUpdateEffects from './onUpdateEffects';
import { rules } from './validationRules';

const dependentProperties = {
  DATE: 'date',
  DEFAULT_IMAGE: 'defaultImage',
  IMAGE: 'image',
  PROJECTS: 'projects',
  BUILDINGS: 'buildings',
  UNITS: 'units',
  CLIENTS: 'clients',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.DATE,
        prop: dependentProperties.DATE,
        label: 'label.date',
        rules: [rules.REQUIRED],
        onlyFutureDate: true,
      },
      {
        type: schemaItemTypes.TIME,
        prop: 'time',
        label: 'label.time',
        rules: [rules.REQUIRED],
        minuteInterval: 5,
        payload: [
          {
            param: 'isToday',
            from: [dependentProperties.DATE],
            serializer: serializers.IS_TODAY,
          },
        ],
      },
    ],
  },
  {
    type: schemaItemTypes.STRING,
    prop: 'title',
    label: 'label.title',
    rules: [rules.REQUIRED],
  },
  {
    type: schemaItemTypes.STRING,
    prop: 'content',
    label: 'label.content',
    rules: [rules.REQUIRED],
    textarea: true,
  },
  {
    type: schemaItemTypes.IMAGE_LIST,
    prop: dependentProperties.DEFAULT_IMAGE,
    label: 'label.default_image',
    selectable: true,
    editable: false,
    removeLabelSuffix: true,
    onUpdate: [
      {
        type: onUpdateEffects.RESET_SELECTED_PROPERTY,
        to: dependentProperties.IMAGE,
      },
    ],
  },
  {
    type: schemaItemTypes.IMAGE_LIST,
    prop: dependentProperties.IMAGE,
    label: 'label.loaded_image',
    selectable: true,
    editable: true,
    service: 'announcement',
    removeLabelSuffix: true,
    onUpdate: [
      {
        type: onUpdateEffects.RESET_SELECTED_PROPERTY,
        to: dependentProperties.DEFAULT_IMAGE,
      },
    ],
  },
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        type: schemaItemTypes.BOOLEAN,
        prop: 'isPushNotificationEnabled',
        label: 'label.push_notification',
      },
      {
        type: schemaItemTypes.BOOLEAN,
        prop: 'isEmailNotificationEnabled',
        label: 'label.email_notification',
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_PROJECTS,
    prop: dependentProperties.PROJECTS,
    label: 'label.projects',
    rules: [rules.MANY_REQUIRED],
    payload: [
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.BUILDINGS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_BUILDINGS,
    prop: dependentProperties.BUILDINGS,
    label: 'label.buildings',
    rules: [rules.MANY_REQUIRED],
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.PROJECTS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.UNITS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_UNITS,
    prop: dependentProperties.UNITS,
    label: 'label.units',
    rules: [rules.MANY_REQUIRED],
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'clients',
        from: [dependentProperties.CLIENTS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.BUILDINGS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CLIENTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_CLIENTS,
    prop: dependentProperties.CLIENTS,
    label: 'label.clients',
    rules: [rules.MANY_REQUIRED],
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.UNITS,
      },
    ],
  },
];

export default schema;
